'use client';

import React, { useState, useEffect, useRef } from 'react';
import Image from '@tiptap/extension-image';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import classNames from 'classnames';

import useTouchDetect from '../../../../../../helpers/hooks/useTouchDetect';

import { ButtonNew as Button } from '../../../../../../components/atoms/Button';

import Icon from '../../../../../../components/atoms/Icon';

import binIcon from '../../../../../../img/sprites/bin-alt.svg';

export const ImageExtension = Image.extend({
  addAttributes() {
    return {
      src: { default: null },
      alt: { default: null },
      title: { default: null },
      width: { default: '100%' },
      height: { default: null },
      align: { default: 'center' }
    };
  },

  addNodeView: () => {
    return ReactNodeViewRenderer(TiptapImage);
  }
});

function TiptapImage(props) {
  const { isTouch } = useTouchDetect();
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  const { node, editor, deleteNode } = props;
  const imageRef = useRef(null);
  const nodeRef = useRef(null);

  useEffect(() => {
    setDeleteVisible(isTouch);
  }, [isTouch]);

  return (
    <NodeViewWrapper
      ref={nodeRef}
      // className={cn(
      //   'relative flex flex-col rounded-md border-2 border-transparent',
      //   selected ? 'border-blue-300' : '',
      //   node.attrs.align === 'left' && 'left-0 -translate-x-0',
      //   node.attrs.align === 'center' && 'left-1/2 -translate-x-1/2',
      //   node.attrs.align === 'right' && 'left-full -translate-x-full'
      // )}
      style={{ width: node.attrs.width }}
      onMouseEnter={() => setDeleteVisible(true)}
      onMouseLeave={() => setDeleteVisible(false)}
    >
      <div className="group relative flex justify-center">
        <img
          ref={imageRef}
          src={node.attrs.src}
          alt={node.attrs.alt}
          title={node.attrs.title}
          style={{ objectFit: 'contain', width: 'fit-content' }}
        />

        {editor?.isEditable && (
          <div className="relative justify-self-end">
            <div className="absolute p-1 right-0 inline-flex justify-end gap-4">
              {/*<Button*/}
              {/*  size="tiny"*/}
              {/*  kind="ghost"*/}
              {/*  onClick={() => updateAttributes({ align: 'left' })}*/}
              {/*>*/}
              {/*  <AlignLeft className="size-4" />*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*  size="tiny"*/}
              {/*  kind="ghost"*/}
              {/*  onClick={() => updateAttributes({ align: 'center' })}*/}
              {/*>*/}
              {/*  <AlignCenter className="size-4" />*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*  size="tiny"*/}
              {/*  kind="ghost"*/}
              {/*  onClick={() => updateAttributes({ align: 'right' })}*/}
              {/*>*/}
              {/*  <AlignRight className="size-4" />*/}
              {/*</Button>*/}
              <Button
                size="small"
                color="red"
                onClick={deleteNode}
                className={classNames(
                  'transition-opacity duration-250',
                  isDeleteVisible ? 'opacity-100' : 'opacity-0'
                )}
              >
                <span className="mr-05">
                  <Icon glyph={binIcon} width={12} height={12} />
                </span>
                Remove
              </Button>
            </div>
          </div>
        )}
      </div>
    </NodeViewWrapper>
  );
}
