import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { getResponseValue } from '../../../../../helpers/api';
import { uploadCampaignLogo } from '../../../../../actions/campaign-builder';
import isEmpty from 'lodash/isEmpty';

import { ButtonNew as Button } from '../../../../../components/atoms/Button';
import Icon from '../../../../../components/atoms/Icon';
import Spacing from '../../../../../components/atoms/Spacing';
import LoadingSpinner from '../../../../../components/atoms/Button/ButtonNew/LoadingSpinner';
import Heading from '../../../../../components/atoms/Heading';
import Paragraph from '../../../../../components/atoms/Paragraph';
import Alert from '../../../../../components/atoms/Alert';

import { ModalNew as Modal } from '../../../../../components/molecules/Modal';

import uploadIcon from '../../../../../img/sprites/upload.svg';

const MAX_FILE_SIZE = 10000000; // 10MB

function fileSizeValidator(file) {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'size-too-large',
      message: `File size is larger than 10MB`
    };
  }

  return null;
}

const LogoUploader = ({ isOpen, onSubmit, onClose, campaignId }) => {
  const dispatch = useDispatch();
  const [thumb, setThumb] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleDrop = async (files) => {
    setUploadError(null);

    const file = files[0];

    try {
      setThumb({ ...file, preview: URL.createObjectURL(file) });
      setFile(file);
    } catch (err) {
      // Errors handled by react-dropzone (fileRejections)
    }
  };

  const handleDropError = (err) => {
    setUploadError(err);
  };

  const handleFileDialogOpen = () => {
    setFile(null);
  };

  const handleSave = async () => {
    const data = new FormData();

    data.append('files', file);
    data.append('campaignId', campaignId);

    try {
      setLoading(true);

      // eslint-disable-next-line
      const [_, imageError] = await getResponseValue(
        dispatch(uploadCampaignLogo(file.preview, data))
      );

      if (imageError) throw imageError;

      onSubmit?.();
    } catch (err) {
      setUploadError(err);
    } finally {
      setThumb(null);
      setFile(null);
      setLoading(false);
    }
  };

  const {
    fileRejections,
    open,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    multiple: false,
    onFileDialogOpen: handleFileDialogOpen,
    onDrop: handleDrop,
    onError: handleDropError,
    validator: fileSizeValidator
  });

  const handleClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (isEmpty(fileRejections)) return;

    const errorMessage = fileRejections[0].errors[0]?.message;

    setUploadError(errorMessage);
  }, [fileRejections]);

  const thumbProps = {
    style: { width: '120px', objectFit: 'cover' }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Spacing size={4} position="t">
        <div className="flex justify-center">
          <div
            {...getRootProps()}
            className="flex items-center justify-center bg-grey-lightest rounded-sm"
            style={{
              width: '200px',
              height: '200px',
              border: '1px dashed gray'
            }}
          >
            {isLoading ? (
              <LoadingSpinner
                kind="solid"
                size="large"
                color="white"
                style={{ top: '17rem' }}
              />
            ) : (
              <Icon glyph={uploadIcon} width={30} height={30} />
            )}
            <input {...getInputProps()} className="hidden" />
          </div>
        </div>
      </Spacing>
      <Spacing size={2} position="t">
        <Heading size="xs" tag="h3" center>
          {isDragActive ? 'Drop the file here' : 'Drag and drop file here'}
        </Heading>
      </Spacing>
      {file && (
        <Spacing size={1} position="t">
          <aside>
            <Spacing size={5} position="t">
              {thumb?.preview && (
                <div className="my-1 flex justify-center">
                  <img
                    src={thumb.preview}
                    alt="Preview"
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(thumb.preview);
                    }}
                    {...thumbProps}
                  />
                </div>
              )}
              <Paragraph size="xxs" className="ellipsis" center>
                {file.name}
              </Paragraph>
            </Spacing>
          </aside>
        </Spacing>
      )}
      {uploadError ? (
        <Spacing size={15} position="t">
          <div className="flex justify-center">
            <Alert kind="error">{uploadError}</Alert>
          </div>
        </Spacing>
      ) : null}
      <Spacing size={5} position="t">
        <Paragraph size="xxs" center>
          JPG or PNG, 10MB max.
        </Paragraph>
      </Spacing>
      <Spacing size={2} position="t">
        <div className="flex gap-4 justify-center">
          <Button onClick={open} kind={file ? 'outline' : undefined}>
            Select
          </Button>
          {file && <Button onClick={handleSave}>Save</Button>}
        </div>
      </Spacing>
    </Modal>
  );
};

export default LogoUploader;
