import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Wizard } from 'react-use-wizard';

import config from '../../config';

import useLocalStorage from '../../helpers/hooks/useLocalStorage';

import Page from '../../components/utils/Page';

import PageWrap from '../../components/atoms/PageWrap';

import { ModalNew as Modal } from '../../components/molecules/Modal';
import AuthenticationContainer from '../../components/molecules/AuthenticationContainer';

import Navigation from './Navigation';
import ArtworkUploader from './ArtworkUploader';
import PriceCalculator from './PriceCalculator';
import PageEditor from './PageEditor';
import MobileOverlay from './MobileOverlay';

import generalStyles from './style/general.module.css';

const CampaignBuilder = () => {
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [builderLsState] = useLocalStorage(config.localStorageBuilderKey);
  const campaignId = useSelector((state) => state.campaignBuilder.campaignId);

  useEffect(() => {
    const mql = window.matchMedia(`(min-width: 1200px)`);

    const handleViewportChange = () => {
      setMobile(!mql.matches);
    };

    handleViewportChange();

    mql.addEventListener('change', handleViewportChange);

    return () => mql.removeEventListener('change', handleViewportChange);
  }, []);

  const handleAuthSuccess = () => {
    setAuthModalOpen(false);
  };

  const step = campaignId ? builderLsState?.step ?? 0 : 0;

  if (isMobile) return <MobileOverlay />;

  return (
    <Page title="Create Campaign">
      <PageWrap className="no-overflow-anchor">
        <Modal isOpen={authModalOpen}>
          <AuthenticationContainer
            initialUserStage="signIn"
            onSuccess={handleAuthSuccess}
          />
        </Modal>

        <div className={generalStyles.container}>
          <Wizard startIndex={step} header={<Navigation />}>
            <ArtworkUploader />
            <PriceCalculator />
            <PageEditor />
          </Wizard>
        </div>
      </PageWrap>
    </Page>
  );
};

export default CampaignBuilder;
