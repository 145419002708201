import { nanoid } from 'nanoid';

export const saveUserProfile = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'SAVE_USER_PROFILE',
    payload: baseApi.put('/user/profile', data),
    meta: data
  });

export const updateUserProfile = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'UPDATE_USER_PROFILE',
    payload: baseApi.post('/user/profile', data),
    meta: data
  });

export const saveUserProfilePatch = (data) => (dispatch, _, { baseApi }) => {
  const { name, ...otherData } = data;

  const meta = {
    ...otherData,
    ...(name ? { profileName: name } : {}),
    optimist: { id: nanoid() }
  };

  dispatch({
    type: 'SAVE_USER_PROFILE_PATCH',
    payload: baseApi.patch('/user/profile', data),
    meta
  });
};

export const saveUserProfilePicture = (file, formData) => (
  dispatch,
  _,
  { fileUploaderApi }
) =>
  dispatch({
    type: 'SAVE_USER_PROFILE_PICTURE',
    payload: fileUploaderApi.post('/profile_picture/upload', formData),
    meta: file
  });

export const saveUserProfileBanner = (file, formData) => (
  dispatch,
  _,
  { fileUploaderApi }
) =>
  dispatch({
    type: 'SAVE_USER_PROFILE_BANNER',
    payload: fileUploaderApi.post('/profile_banner/upload', formData),
    meta: file
  });

export const getUserProfile = () => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_USER_PROFILE',
    payload: baseApi.get(`/user/profile`)
  });

export const getProfile = (slug) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_PROFILE',
    payload: baseApi.get(`/profile/${slug}`)
  });

export const getUserProfileCampaigns = () => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_USER_PROFILE_CAMPAIGNS',
    payload: baseApi.get(`/user/profile/campaigns`)
  });

export const getProfileCampaigns = (slug) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'GET_PROFILE_CAMPAIGNS',
    payload: baseApi.get(`/profile/${slug}/campaigns`),
    meta: slug
  });

export const hideProfileCampaign = (campaignId, hiddenOnProfile) => (
  dispatch,
  _,
  { baseApi }
) => {
  dispatch({
    type: 'HIDE_PROFILE_CAMPAIGN',
    payload: baseApi.put(`/user/profile/campaign/${campaignId}`, {
      hiddenOnProfile
    }),
    meta: {
      campaignId,
      hiddenOnProfile
    }
  });
};
