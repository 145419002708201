import React, { useState, useEffect, useRef } from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import classNames from 'classnames';

import useTouchDetect from '../../../../../../helpers/hooks/useTouchDetect';

import { ButtonNew as Button } from '../../../../../../components/atoms/Button';
import Icon from '../../../../../../components/atoms/Icon';

import binIcon from '../../../../../../img/sprites/bin-alt.svg';

// Optional: Define window.iframely globally for JS (won't error but use cautiously)
if (typeof window !== 'undefined' && !window.iframely) {
  window.iframely = {
    load: () => {}
  };
}

export const IframelyExtension = Node.create({
  name: 'iframely',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="iframely-embed"]'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { 'data-type': 'iframely-embed' })
    ];
  },

  addCommands() {
    return {
      setIframelyEmbed: (options) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options
        });
      }
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(({ node, editor, deleteNode }) => {
      const { isTouch } = useTouchDetect();
      const [isDeleteVisible, setDeleteVisible] = useState(false);
      const containerRef = useRef(null);
      const loaded = useRef(false);

      useEffect(() => {
        setDeleteVisible(isTouch);
      }, [isTouch]);

      useEffect(() => {
        if (!containerRef.current || loaded.current) return;

        let interval;

        const tryLoad = () => {
          if (window.iframely && typeof window.iframely.load === 'function') {
            window.iframely.load(containerRef.current, node.attrs.src);
            loaded.current = true;
            clearInterval(interval);
          }
        };

        interval = setInterval(tryLoad, 200);

        return () => clearInterval(interval);
      }, [node.attrs.src]);

      return (
        <NodeViewWrapper
          onMouseEnter={() => setDeleteVisible(true)}
          onMouseLeave={() => setDeleteVisible(false)}
        >
          <div ref={containerRef} className="not-prose my-8 overflow-hidden">
            {editor?.isEditable && (
              <div className="relative w-full h-full z-50">
                <div className="absolute p-1 top-0 right-0">
                  <Button
                    size="small"
                    color="red"
                    onClick={deleteNode}
                    className={classNames(
                      'transition-opacity duration-250',
                      isDeleteVisible ? 'opacity-100' : 'opacity-0'
                    )}
                  >
                    <span className="mr-05">
                      <Icon glyph={binIcon} width={12} height={12} />
                    </span>
                    Remove
                  </Button>
                </div>
              </div>
            )}
          </div>
        </NodeViewWrapper>
      );
    });
  }
});
