import jwtDecode from 'jwt-decode';
import { isLocationAction } from 'redux-first-router';

import { getTimeRemainingInSeconds } from '../helpers/utils';

import * as userActions from '../actions/user';

import config from '../config';

export default function jwtMiddleware() {
  return ({ dispatch, getState }) => (next) => (action) => {
    const {
      token,
      refreshToken,
      refreshTokenExpiration,
      isLoading
    } = getState().user;
    const routeHasChanged = isLocationAction(action) === true;
    const isBuilderAction = action.type.includes('BUILDER');

    if (routeHasChanged || isBuilderAction) {
      if (token) {
        const { exp: tokenExp } = jwtDecode(token);

        const tokenExpTimeInSeconds = getTimeRemainingInSeconds(tokenExp);

        // If required, we can force early invalidation by adjusting the threshold in config
        const isTokenExpired =
          !!tokenExp && tokenExpTimeInSeconds <= config.jwtExpirationThreshold;

        if (isTokenExpired && !refreshToken) {
          dispatch(userActions.logout({ resetBuilderCampaignId: false }));
        }

        let isRefreshTokenExpired = true;

        if (refreshToken) {
          if (refreshTokenExpiration) {
            const refreshTokenExpTimeInSeconds = getTimeRemainingInSeconds(
              refreshTokenExpiration
            );

            isRefreshTokenExpired =
              refreshTokenExpTimeInSeconds <=
              config.refreshTokenExpirationThreshold;
          }

          if (isRefreshTokenExpired || !refreshTokenExpiration) {
            dispatch(userActions.setUserRefreshToken(null, null));
          }
        }

        if (
          isTokenExpired &&
          refreshToken &&
          !isRefreshTokenExpired &&
          !isLoading
        ) {
          dispatch(userActions.refreshToken(refreshToken));
        }
      }
    }

    return next(action);
  };
}
