import React, { useEffect } from 'react';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import GlobalDragHandle from 'tiptap-extension-global-drag-handle';

import config from '../../../../../config';

import { ButtonNew as Button } from '../../../../../components/atoms/Button';

import { ImageExtension } from '../Extensions/Image';
import { ImagePlaceholder } from '../Extensions/ImagePlaceholder';
import { IframelyExtension } from '../Extensions/Iframely';
import { IframelyPlaceholder } from '../Extensions/IframelyPlaceholder';

import './style/globals.css';

import Alert from '../../../../../components/atoms/Alert';
import Icon from '../../../../../components/atoms/Icon';

import textIcon from '../../../../../img/sprites/text.svg';
import h1Icon from '../../../../../img/sprites/h-1.svg';
import h2Icon from '../../../../../img/sprites/h-2.svg';
import imageIcon from '../../../../../img/sprites/image-line.svg';
import linkIcon from '../../../../../img/sprites/link-m.svg';
import deleteIcon from '../../../../../img/sprites/delete-back-2-line.svg';

const AsyncIframelyLoader = () => {
  useEffect(() => {
    if (typeof window.iframely === 'undefined' || !window.iframely?._loaded) {
      const script = document.createElement('script');
      script.src = `//cdn.iframe.ly/embed.js?api_key=${config.iframelyApiKey}`;
      script.async = true;

      document.body.appendChild(script);

      // Don't clean up on unmount
    }
  }, []);

  return null;
};

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  return (
    <div className="control-group">
      <div className="w-full flex justify-between button-group">
        <div className="flex inline-flex gap-4">
          <Button
            size="small"
            kind="outline"
            onClick={() =>
              editor
                .chain()
                .focus()
                .setParagraph()
                .run()
            }
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            <span className="mr-05">
              <Icon glyph={textIcon} width={12} height={12} />
            </span>
            Text
          </Button>
          <Button
            size="small"
            kind="outline"
            onClick={() =>
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 1 })
                .run()
            }
            className={
              editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
            }
          >
            <span className="mr-05">
              <Icon glyph={h1Icon} width={12} height={12} />
            </span>
            Heading
          </Button>
          <Button
            size="small"
            kind="outline"
            onClick={() =>
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 2 })
                .run()
            }
            className={
              editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
            }
          >
            <span className="mr-05">
              <Icon glyph={h2Icon} width={12} height={12} />
            </span>
            Subheading
          </Button>
          <Button
            size="small"
            kind="outline"
            onClick={() => editor.commands.insertImagePlaceholder()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            <span className="mr-05">
              <Icon glyph={imageIcon} width={12} height={12} />
            </span>
            Image
          </Button>
          <Button
            size="small"
            kind="outline"
            onClick={() => editor.commands.insertIframelyPlaceholder()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            <span className="mr-05">
              <Icon glyph={linkIcon} width={12} height={12} />
            </span>
            Embed Link
          </Button>
        </div>

        <div className="justify-self-end">
          <Button
            size="small"
            color="red"
            kind="outline"
            onClick={() => editor.commands.clearContent()}
          >
            <span className="mr-05">
              <Icon glyph={deleteIcon} width={12} height={12} />
            </span>
            Clear all
          </Button>
        </div>
      </div>

      <div className="-ml-25 -mr-25 mt-25">
        <hr className="border-grey-lighter" />
      </div>
    </div>
  );
};

const extensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2]
    },
    history: false,
    hardBreak: false,
    blockQuote: false,
    bulletList: false,
    codeBlock: false,
    horizontalRule: false,
    listItem: false,
    orderedList: false
  }),
  Placeholder.configure({
    placeholder: 'Start typing here...'
  }),
  IframelyExtension,
  IframelyPlaceholder,
  ImageExtension,
  ImagePlaceholder,
  GlobalDragHandle.configure({
    dragHandleWidth: 35, // default

    // The scrollTreshold specifies how close the user must drag an element to the edge of the lower/upper screen for automatic
    // scrolling to take place. For example, scrollTreshold = 100 means that scrolling starts automatically when the user drags an
    // element to a position that is max. 99px away from the edge of the screen
    // You can set this to 0 to prevent auto scrolling caused by this extension
    scrollTreshold: 0, // default

    // The css selector to query for the drag handle. (eg: '.custom-handle').
    // If handle element is found, that element will be used as drag handle.
    // If not, a default handle will be created
    dragHandleSelector: '.drag-handle', // default is undefined

    // Tags to be excluded for drag handle
    // If you want to hide the global drag handle for specific HTML tags, you can use this option.
    // For example, setting this option to ['p', 'hr'] will hide the global drag handle for <p> and <hr> tags.
    excludedTags: [], // default

    // Custom nodes to be included for drag handle
    // For example having a custom Alert component. Add data-type="alert" to the node component wrapper.
    // Then add it to this list as ['alert']
    //
    customNodes: [
      'image',
      'image-placeholder',
      'iframely',
      'iframely-placeholder'
    ]
  })
];

export default ({ content, contentError, onSave }) => {
  const handleSave = async ({ editor }) => {
    const contentJson = editor?.getJSON();

    const cleanJsonContent = {
      ...contentJson,
      content: contentJson?.content?.filter(
        (node) => !node.type.includes('placeholder')
      )
    };

    if (cleanJsonContent) {
      return await onSave?.(cleanJsonContent);
    }
  };

  return (
    <>
      <AsyncIframelyLoader />

      <div className="tiptap p-25 border-1 border-grey-lighter rounded-md">
        {contentError && (
          <div className="mb-2">
            <Alert>{contentError}</Alert>
          </div>
        )}

        <EditorProvider
          slotBefore={<MenuBar />}
          extensions={extensions}
          content={content}
          immediatelyRender={false}
          onBlur={handleSave}
        ></EditorProvider>
      </div>
    </>
  );
};
