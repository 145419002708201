import produce from 'immer';
import * as persistentState from '../services/local-storage';

import config from '../config';

import merge from 'lodash/merge';

const initialState = {
  campaignId: null,
  artworkPositions: [],
  featuredProduct: {},
  catalogueProducts: [],
  campaignProducts: [],
  logoUrl: null,
  artwork: {},
  profitEstimate: {},
  length: 21,
  goal: 50,
  promoOptIn: true,
  currencyIso: 'GBP',
  step2TimesViewed: 0,
  darkColoursToastDismissed: false,
  selfServeTags: [],
  status: 'idle'
};

export default (
  state = {
    ...initialState,
    ...{ campaignId: persistentState.get(config.localStorageCampaignIdKey) },
    ...{
      darkColoursToastDismissed: persistentState.get(
        config.localStorageBuilderKey
      )?.darkColoursToastDismissed
    }
  },
  action
) =>
  produce(state, (newState) => {
    const { type, payload, meta } = action;

    switch (type) {
      case 'SET_USER':
        // Would be better to use a separate dispatch to do this
        if (payload === null && meta.options?.resetBuilderCampaignId) {
          newState.campaignId = initialState.campaignId;
          return newState;
        }
        return newState;
      case 'RESET_BUILDER':
        newState = initialState;
        return newState;
      case 'GET_BUILDER_CAMPAIGN_PENDING':
      case 'GET_BUILDER_CATALOGUE_PRODUCTS_PENDING':
      case 'GET_BUILDER_ARTWORK_POSITIONS_PENDING':
      case 'ADD_BUILDER_CAMPAIGN_PRODUCT_PENDING':
        newState.status = 'pending';
        return newState;
      case 'DELETE_BUILDER_CAMPAIGN_PRODUCT_PENDING':
        newState.status = 'deleting';
        return newState;
      case 'GET_BUILDER_CAMPAIGN_PRODUCTS_PENDING':
      case 'UPDATE_BUILDER_CAMPAIGN_PRODUCT_PENDING':
      case 'UPDATE_BUILDER_PROFIT_ESTIMATE_PENDING':
      case 'UPDATE_BUILDER_CAMPAIGN_PENDING':
        newState.status = 'updating';
        return newState;
      case 'UPLOAD_BUILDER_ARTWORK_PENDING':
        newState.status = 'uploading';
        return newState;
      case 'CREATE_BUILDER_CAMPAIGN_REJECTED':
      case 'GET_BUILDER_CAMPAIGN_REJECTED':
      case 'GET_BUILDER_CATALOGUE_PRODUCTS_REJECTED':
      case 'GET_BUILDER_CAMPAIGN_PRODUCTS_REJECTED':
      case 'GET_BUILDER_ARTWORK_POSITIONS_REJECTED':
      case 'ADD_BUILDER_CAMPAIGN_PRODUCT_REJECTED':
      case 'DELETE_BUILDER_CAMPAIGN_PRODUCT_REJECTED':
      case 'UPDATE_BUILDER_CAMPAIGN_PRODUCT_REJECTED':
      case 'UPDATE_BUILDER_PROFIT_ESTIMATE_REJECTED':
      case 'UPDATE_BUILDER_CAMPAIGN_REJECTED':
      case 'UPLOAD_BUILDER_ARTWORK_REJECTED':
        newState.status = 'rejected';
        return newState;
      case 'CREATE_BUILDER_CAMPAIGN_FULFILLED':
        newState.campaignId = payload.campaignId;
        newState.status = 'resolved';
        return newState;
      case 'GET_BUILDER_CAMPAIGN_FULFILLED':
        newState = merge({}, state, payload);
        newState.status = 'resolved';
        return newState;
      case 'GET_BUILDER_CATALOGUE_PRODUCTS_FULFILLED':
        newState.catalogueProducts = payload;
        newState.featuredProduct =
          payload.find((product) => product.featured) || payload[0];
        newState.status = 'resolved';
        return newState;
      case 'GET_BUILDER_CAMPAIGN_PRODUCTS_FULFILLED':
        newState.campaignProducts = payload;
        newState.status = 'resolved';
        return newState;
      case 'UPLOAD_BUILDER_ARTWORK_FULFILLED':
        newState.artwork = payload.files[0];
        newState.status = 'resolved';
        return newState;
      case 'UPDATE_BUILDER_ARTWORK_FULFILLED':
        newState.artwork = payload;
        newState.status = 'resolved';
        return newState;
      case 'DELETE_BUILDER_ARTWORK':
        newState.artwork = initialState.artwork;
        return newState;
      case 'CREATE_BUILDER_ARTWORK_POSITIONS_FULFILLED':
        newState.artworkPositions.push(payload);
        newState.status = 'resolved';
        return newState;
      case 'GET_BUILDER_ARTWORK_POSITIONS_FULFILLED':
        newState.artworkPositions = payload;
        newState.status = 'resolved';
        return newState;
      case 'UPDATE_BUILDER_ARTWORK_POSITIONS_FULFILLED':
        newState.artworkPositions = state.artworkPositions.map((pos) => {
          return pos.campaignArtworkPositionId ===
            payload.campaignArtworkPositionId
            ? payload
            : pos;
        });
        newState.status = 'resolved';
        return newState;
      case 'DELETE_BUILDER_ARTWORK_POSITION_FULFILLED':
        newState.artworkPositions = state.artworkPositions.filter(
          (pos) =>
            pos.campaignArtworkPositionId !== meta.campaignArtworkPositionId
        );
        newState.status = 'resolved';
        return newState;
      case 'ADD_BUILDER_CAMPAIGN_PRODUCT_FULFILLED':
        const newProduct = {
          ...payload,
          product: { ...payload.product, ...meta.productData.product }
        };
        newState.campaignProducts = state.campaignProducts.map((product) => {
          return {
            ...product,
            // Unfeature other products if target product is featured
            featured: payload.featured ? false : product.featured
          };
        });
        newState.campaignProducts.push(newProduct);
        newState.status = 'resolved';
        return newState;
      case 'DELETE_BUILDER_CAMPAIGN_PRODUCT_FULFILLED':
        newState.campaignProducts = state.campaignProducts.filter(
          (product) => product.campaignProductId !== meta.campaignProductId
        );
        newState.status = 'resolved';
        return newState;
      case 'UPDATE_BUILDER_CAMPAIGN_PRODUCT_FULFILLED':
        newState.campaignProducts = state.campaignProducts.map((product) => {
          return product.campaignProductId === payload.campaignProductId
            ? {
                ...payload,
                product: { ...payload.product, ...meta.productData.product }
              }
            : {
                ...product,
                // Unfeature other products if target product is featured
                featured: payload.featured ? false : product.featured
              };
        });
        newState.status = 'resolved';
        return newState;
      case 'UPDATE_BUILDER_CAMPAIGN_ID':
        newState.campaignId = payload;
        return newState;
      case 'UPDATE_BUILDER_QUANTITY':
        newState.quantity = payload;
        return newState;
      case 'UPDATE_BUILDER_PROMO_OPT_IN':
        newState.promoOptIn = payload;
        return newState;
      case 'UPDATE_BUILDER_PROFIT_ESTIMATE_FULFILLED':
        newState.profitEstimate = payload;
        newState.status = 'resolved';
        return newState;
      case 'UPDATE_BUILDER_CAMPAIGN_FULFILLED':
        newState = merge({}, state, payload);
        newState.status = 'resolved';
        return newState;
      case 'INCREMENT_STEP2_TIMES_VIEWED':
        newState.step2TimesViewed = state.step2TimesViewed + 1;
        return newState;
      case 'UPDATE_DARK_COLOURS_TOAST_DISMISSED':
        newState.darkColoursToastDismissed = payload;
        return newState;
      case 'LAUNCH_CAMPAIGN_FULFILLED':
        newState = initialState;
        return newState;
      case 'GET_SELF_SERVE_TAGS_FULFILLED':
        newState.selfServeTags = payload;
        return newState;
      case 'UPLOAD_CAMPAIGN_LOGO_FULFILLED':
        newState.logoUrl = payload?.files[0].url;
        return newState;
      case 'DELETE_CAMPAIGN_LOGO_FULFILLED':
        newState.logoUrl = initialState.logoUrl;
        return newState;
      case 'UPDATE_BUILDER_CAMPAIGN_TAGS_FULFILLED':
        newState.tags = payload.tags;
        return newState;
      default:
        return newState;
    }
  });
