import React, { useState } from 'react';
import {
  Node,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes
} from '@tiptap/react';
import * as yup from 'yup';

import urlRegex from '../../../../../../helpers/urlRegex';

import { ButtonNew as Button } from '../../../../../../components/atoms/Button';
import Icon from '../../../../../../components/atoms/Icon';
import Spacing from '../../../../../../components/atoms/Spacing';
import Paragraph from '../../../../../../components/atoms/Paragraph';
import Alert from '../../../../../../components/atoms/Alert';
import {
  DefaultField as Field,
  Form
} from '../../../../../../components/atoms/Form';

import { ModalNew as Modal } from '../../../../../../components/molecules/Modal';

import linkIcon from '../../../../../../img/sprites/link-m.svg';
import instagramIcon from '../../../../../../img/sprites/instagram-line.svg';
import facebookIcon from '../../../../../../img/sprites/facebook-line.svg';
import twitterIcon from '../../../../../../img/sprites/twitter-x-line.svg';
import youtubeIcon from '../../../../../../img/sprites/youtube-line.svg';

const NODE_HANDLES_SELECTED_STYLE_CLASSNAME = 'node-handles-iframely';

export const IframelyPlaceholder = Node.create({
  name: 'iframely-placeholder',

  addOptions() {
    return {
      HTMLAttributes: {},
      onEmbed: () => {}
    };
  },

  group: 'block',

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframelyPlaceholderComponent, {
      className: NODE_HANDLES_SELECTED_STYLE_CLASSNAME
    });
  },

  addCommands() {
    return {
      insertIframelyPlaceholder: () => ({ commands, state }) => {
        const pos = Math.max(0, state.selection.$anchor.pos);

        return commands.insertContentAt(
          pos,
          {
            type: 'iframely-placeholder'
          },
          { updateSelection: true }
        );
      }
    };
  }
});

function IframelyPlaceholderComponent({ editor }) {
  const [saveError, setSaveError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSave = async ({ url }) => {
    setSaveError(null);

    try {
      if (url) {
        editor
          .chain()
          .focus()
          .setIframelyEmbed({
            src: url
          })
          .deleteNode('iframely-placeholder')
          .run();
      }

      setModalOpen(false);
    } catch (err) {
      setSaveError('Unable to load URL, please try again later.');
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <NodeViewWrapper className="w-full">
      <div className="ml-1">
        <Button onClick={handleOpen} kind="ghost">
          <span className="mr-1">
            <Icon glyph={linkIcon} width={19} height={19} />
          </span>
          Embed link
        </Button>
      </div>
      <Modal isOpen={isModalOpen} handleClose={handleClose}>
        <Spacing size={5} position="t">
          <div className="w-full">
            <Form
              onSubmit={handleSave}
              initialValues={{
                url: ''
              }}
              validationSchema={yup.object().shape({
                url: yup
                  .string()
                  .matches(urlRegex, 'Please enter a valid link')
                  .max(250)
                  .required('Please enter a valid link')
              })}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="w-full inline-flex items-start gap-3">
                      {/* Extra flex wrapper for validation errors */}
                      <div className="w-full flex flex-col">
                        <Field
                          name="url"
                          placeholder="Enter a URL from any source"
                        />
                      </div>
                      <Button type="submit">Save</Button>
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </Spacing>
        {saveError ? (
          <Spacing size={15} position="t">
            <div className="flex justify-center">
              <Alert kind="error">{saveError}</Alert>
            </div>
          </Spacing>
        ) : null}
        <Spacing size={5} position="t">
          <div className="w-full inline-flex justify-center gap-3">
            <Icon glyph={instagramIcon} width={18} height={18} />
            <Icon glyph={facebookIcon} width={18} height={18} />
            <Icon glyph={youtubeIcon} width={18} height={18} />
            <Icon glyph={twitterIcon} width={18} height={18} />
          </div>
        </Spacing>
        <Spacing size={2} position="t">
          <Paragraph size="xxs" center>
            We don’t support Instagram profile embeds at the moment :( We
            suggest embedding a single post instead.
          </Paragraph>
        </Spacing>
      </Modal>
    </NodeViewWrapper>
  );
}
