export const setUser = (user) => {
  return { type: 'SET_USER', payload: user };
};

export const getUser = () => (dispatch, getState, { baseApi }) => {
  const token = getState().user.token;
  return dispatch({
    type: 'GET_USER',
    payload: baseApi.get(`/user`),
    meta: { token }
  });
};

export const getUserPreferences = () => (dispatch, getState, { baseApi }) =>
  dispatch({
    type: 'GET_USER_PREFERENCES',
    payload: baseApi.get(`/user/preferences`)
  });

export const getEmailUserPreferences = () => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'GET_EMAIL_USER_PREFERENCES',
    payload: baseApi.get(`/user/preferences/email`)
  });

export const logout = (options = { resetBuilderCampaignId: true }) => (
  dispatch
) => {
  dispatch({
    type: 'SET_USER',
    payload: null,
    meta: {
      resetBuilderCampaignId: options.resetBuilderCampaignId
    }
  });
};

export const setUserRoles = () => (dispatch, getState) =>
  dispatch({
    type: 'SET_USER_ROLES',
    payload: {
      token: getState().user.token,
      refreshToken: getState().user.refreshToken
    }
  });

export const setUserCurrency = (currency) => (dispatch, getState) =>
  dispatch({
    type: 'SET_USER_CURRENCY',
    payload: currency,
    meta: {
      previousCurrency: getState().user.currency
    }
  });

export const setUserLocale = (locale) => ({
  type: 'SET_USER_LOCALE',
  payload: locale
});

export const login = (email, password) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'LOGIN_USER',
    async payload() {
      const response = await baseApi.post('/login', { email, password });
      if (response && response.token && response.refreshToken) {
        await dispatch(
          setUser({
            token: response.token,
            refreshToken: response.refreshToken,
            refreshTokenExpiration: response.refreshTokenExpiration
          })
        );
        dispatch(setUserRoles());
      }
    }
  });

export const signUp = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'SIGNUP_USER',
    async payload() {
      const response = await baseApi.post('/user/register', {
        ...data,
        emailOptIn: true
      });
      if (response && response.token && response.refreshToken) {
        await dispatch(
          setUser({
            token: response.token,
            refreshToken: response.refreshToken,
            refreshTokenExpiration: response.refreshTokenExpiration
          })
        );
        dispatch(setUserRoles());
      }
    }
  });

export const editUserDetails = (data) => (dispatch, getState, { baseApi }) =>
  dispatch({
    type: 'EDIT_USER_DETAILS',
    async payload() {
      const payload = { ...getState().user.details, ...data };
      const response = await baseApi.put(`/user`, payload);
      if (response && response.token && response.refreshToken) {
        await dispatch(
          setUser({
            token: response.token,
            refreshToken: response.refreshToken,
            refreshTokenExpiration: response.refreshTokenExpiration
          })
        );
        dispatch(setUserRoles({ token: response.token }));
      }
      return payload;
    }
  });

export const editUserCreatorDetails = (data) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'EDIT_USER_CREATOR_DETAILS',
    async payload() {
      const response = await baseApi.put(`/user/preferences/creator`, data);
      if (response && response.token && response.refreshToken) {
        await dispatch(
          setUser({
            token: response.token,
            refreshToken: response.refreshToken,
            refreshTokenExpiration: response.refreshTokenExpiration
          })
        );

        dispatch(setUserRoles({ token: response.token }));
      }
      return data;
    }
  });

export const editUserPreferences = (data) => (
  dispatch,
  getState,
  { baseApi }
) =>
  dispatch({
    type: 'EDIT_USER_PREFERENCES',
    async payload() {
      const payload = { ...getState().user.preferences, ...data };
      await baseApi.put(`/user/preferences`, payload);
      return payload;
    }
  });

export const editUserEmailPreferences = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'EDIT_EMAIL_USER_PREFERENCES',
    async payload() {
      const payload = { ...data };
      await baseApi.put(`/user/preferences/email`, payload);
      return payload;
    }
  });

export const editPassword = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'EDIT_USER_PASSWORD',
    payload: baseApi.post(`/user/password`, data)
  });

export const recoverPassword = (email) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'RECOVER_USER_PASSWORD',
    payload: baseApi.post('/user/forgotten-password', { email })
  });

export const resetPassword = (token, password) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'RESET_USER_PASSWORD',
    payload: baseApi.post(`/user/reset-password/${token}`, { password })
  }).catch((error) => {
    if (error.response && error.response.status === 404) {
      error.message = 'Your email link is expired. Please try again.';
    }
    throw error;
  });

export const lookupUser = (email) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'LOOKUP_USER',
    payload: baseApi.post('/user/email', { email })
  });

export const saveUserCheckoutDetails = (data) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: 'SAVE_USER_CHECKOUT_DETAILS',
    payload: baseApi.put(`/user/checkout`, data)
  });

export const editUserOrderDeliveryAddress = (orderId, data) => (
  dispatch,
  _,
  { baseApi }
) =>
  dispatch({
    type: 'EDIT_USER_ORDER_DELIVERY_ADDRESS',
    payload: baseApi.put(`/user/order/${orderId}/address`, data)
  });

export const refreshToken = (refreshToken) => (dispatch, _, { baseApi }) => {
  dispatch({
    type: 'GET_REFRESH_TOKEN',
    async payload() {
      const response = await baseApi.post('/token/refresh', { refreshToken });
      if (response && response.token && response.refreshToken) {
        dispatch(
          setUser({
            token: response.token,
            refreshToken: response.refreshToken,
            refreshTokenExpiration: response.refreshTokenExpiration
          })
        );
        dispatch(setUserRoles());
      }
    }
  });
};

export const followUser = (data) => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'FOLLOW_USER',
    payload: baseApi.post(`/user/follow`, data),
    meta: data
  });
};

export const unfollowUser = (userFolloweeId) => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'UNFOLLOW_USER',
    payload: baseApi.delete(`/user/follow/${userFolloweeId}`),
    meta: userFolloweeId
  });
};

export const getUserFollowees = (options) => (dispatch, _, { baseApi }) => {
  const defaultOptions = {
    offset: 0,
    limit: 10
  };
  const params = { ...defaultOptions, ...options };

  return dispatch({
    type: 'GET_USER_FOLLOWEES',
    payload: baseApi.get(`/user/follow/followees`, { params })
  });
};

export const favouriteCampaign = (data) => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'FAVOURITE_CAMPAIGN',
    payload: baseApi.post(`/user/favourite`, data)
  });
};

export const getUserFavourites = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_USER_FAVOURITES',
    payload: baseApi.get('/user/favourites')
  });
};

export const deleteUserFavourite = ({ userFavouriteId, campaignId }) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'DELETE_USER_FAVOURITE',
    payload: baseApi.delete(`/user/favourite/${userFavouriteId}`),
    meta: {
      userFavouriteId,
      campaignId
    }
  });
};

export const lookupFavourites = (campaignIds) => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'LOOKUP_USER_FAVOURITES',
    payload: baseApi.get(`/user/favourites/lookup`, {
      params: {
        campaignIds
      }
    })
  });
};

export const favouriteCampaignAttempt = ({ campaignId }) => (dispatch) => {
  return dispatch({
    type: 'FAVOURITE_CAMPAIGN_ATTEMPT',
    payload: null,
    meta: { campaignId }
  });
};

export const claimCreatorReward = (data) => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'CLAIM_CREATOR_REWARD',
    payload: baseApi.post(`/user/reward`, data)
  });
};

export const leadSignUp = (data) => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'LEAD_SIGN_UP',
    payload: baseApi.post('/lead', data),
    meta: {
      source: data.source
    }
  });
};

export const setUserRutterToken = (token) => (dispatch) => {
  return dispatch({
    type: 'SET_USER_RUTTER_TOKEN',
    payload: token
  });
};

export const createUserRutterConnection = (token) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'CREATE_USER_RUTTER_CONNECTION',
    payload: baseApi.post('/user/connection', { token })
  });
};

export const createUserRutterCampaignProducts = (campaignId, connectionId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCTS',
    payload: baseApi.post(
      `/user/platform/products/campaign/${campaignId}?connectionId=${connectionId}`
    )
  });
};

export const createUserRutterCampaignProduct = (campaignProductId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'CREATE_USER_RUTTER_CAMPAIGN_PRODUCT',
    payload: baseApi.post(`/user/platform/product/${campaignProductId}`)
  });
};

export const deleteUserRutterCampaignProducts = (campaignId, connectionId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'DELETE_USER_RUTTER_CAMPAIGN_PRODUCTS',
    payload: baseApi.delete(
      `user/platform/products/campaign/${campaignId}?connectionId=${connectionId}`
    )
  });
};

export const getUserFavouriteCategories = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_USER_FAVOURITE_CATEGORIES',
    payload: baseApi.get(`/user/categories/favourite`)
  });
};

export const saveUserFavouriteCategories = (tagIds, tagNames) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'SAVE_USER_FAVOURITE_CATEGORIES',
    payload: baseApi.post(`/user/categories/favourite`, {
      tags: tagIds
    }),
    meta: {
      tags: tagNames
    }
  });
};

export const createUserStripeSetupIntent = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'CREATE_USER_STRIPE_SETUP_INTENT',
    payload: baseApi.post('/user/stripe/setup-intent')
  });
};

export const getUserStripePaymentMethods = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_USER_STRIPE_PAYMENT_METHODS',
    payload: baseApi.get('/user/stripe/payment-methods')
  });
};

export const saveUserStripePaymentMethod = (paymentMethod = {}) => (
  dispatch,
  _,
  { baseApi }
) => {
  if (!paymentMethod?.id) {
    console.warn('Aborting dispatch, please provide a valid payment method');
    return;
  }

  return dispatch({
    type: 'SAVE_USER_STRIPE_PAYMENT_METHOD',
    payload: baseApi.post('/user/stripe/payment-method', paymentMethod)
  });
};

export const deleteUserStripePaymentMethod = (paymentMethod = {}) => (
  dispatch,
  _,
  { baseApi }
) => {
  const { userStripePaymentMethodId } = paymentMethod;

  return dispatch({
    type: 'DELETE_USER_STRIPE_PAYMENT_METHOD',
    payload: baseApi.delete(
      `/user/stripe/payment-method/${userStripePaymentMethodId}`
    )
  });
};

export const setPrimaryUserStripePaymentMethod = (paymentMethod = {}) => (
  dispatch,
  _,
  { baseApi }
) => {
  const { userStripePaymentMethodId } = paymentMethod;

  return dispatch({
    type: 'SET_PRIMARY_USER_STRIPE_PAYMENT_METHOD',
    payload: baseApi.post(
      `/user/stripe/payment-method/${userStripePaymentMethodId}/primary`
    )
  });
};

export const createUserBandcampAccount = (subdomain) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'CREATE_USER_BANDCAMP_STORE',
    payload: baseApi.post('/user/bandcamp/account', {
      subdomain
    })
  });
};

export const getUserBandcampProducts = (campaignId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'GET_USER_BANDCAMP_PRODUCTS',
    payload: baseApi.get(`/user/bandcamp/campaign/${campaignId}/products`)
  });
};

export const createUserBandcampProduct = (product = {}) => (
  dispatch,
  _,
  { baseApi }
) => {
  const { campaignId, campaignProductId, colourId } = product;

  return dispatch({
    type: 'CREATE_USER_BANDCAMP_PRODUCT',
    payload: baseApi.post(
      `/user/bandcamp/campaign/${campaignId}/product/${campaignProductId}/${colourId}`,
      product
    ),
    meta: product
  });
};

export const deleteUserPlatformConnection = (connectionId) => (
  dispatch,
  _,
  { baseApi }
) => {
  return dispatch({
    type: 'DELETE_USER_PLATFORM_CONNECTION',
    payload: baseApi.delete(`/user/connection/${connectionId}`)
  });
};

export const setImpersonatedUser = (userEmail) => (dispatch) => {
  return dispatch({
    type: 'SET_IMPERSONATED_USER',
    payload: userEmail
  });
};

export const getUserCharges = () => (dispatch, _, { baseApi }) => {
  return dispatch({
    type: 'GET_USER_CHARGES',
    payload: baseApi.get('/v1/user/charges')
  });
};

export const setUserRefreshToken = (refreshToken, refreshTokenExpiration) => {
  return {
    type: 'SET_USER_REFRESH_TOKEN',
    payload: { refreshToken, refreshTokenExpiration }
  };
};
